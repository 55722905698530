import { graphql, useStaticQuery } from 'gatsby'

import { AnchorLink } from 'gatsby-plugin-anchor-links'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import { Link } from 'gatsby'
import React from 'react'

const Mvv = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet>
        <html className="mvv" lang="ja" />
        <title>{`ミッション・ビジョン・バリュー | ${title}`}</title>
        <meta
          name="description"
          content="ミッション・ビジョン・バリュー、代表の想いをご紹介いたします。"
        />
      </Helmet>
      <div className="common-header clearfix">
        <h1>ミッション・ビジョン・バリュー</h1>
        <div className="breadcrumb">
          <li>
            <Link to={`/`}>トップページ</Link>
          </li>
          /<li>ミッション・ビジョン・バリュー</li>
        </div>
      </div>
      <div className="main-wrapper">
        <div className="row">
          <div className="container">
            <div className="contents">
              <div className="common-link">
                <AnchorLink
                  to="/mvv#mvv"
                  title="ミッション・ビジョン・バリュー"
                >
                  ミッション・ビジョン・バリュー
                </AnchorLink>
              </div>
              <div className="common-link">
                <AnchorLink to="/mvv#message" title="代表の想い">
                  代表の想い
                </AnchorLink>
              </div>
              <div className="clear"></div>
              <div className="common-link">
                <AnchorLink to="/mvv#value" title="私たちが提供したい価値">
                  私たちが提供したい価値
                </AnchorLink>
              </div>
              <div className="clear"></div>
            </div>
          </div>
        </div>

        <div id="mvv" name="mvv" className="row">
          <div className="h-wrapper">
            <h2>ミッション・ビジョン・バリュー</h2>
          </div>
        </div>
        <div className="row">
          <div className="container mvv">
            <h3>ミッション</h3>
            <h4>未来の「駐車体験」を形にする</h4>
            <p className="pc-only">
              未来の駐車場を通じて、未来の駐車体験をパートナー会社とともに共創する
            </p>
            <h3>ビジョン</h3>
            <h4>
              どこにでもITCの機器・サービスが入っている世の中へ「ITC入っている」
            </h4>
            <p className="pc-only">
              未来に先駆けたITCの機器サービスを運営会社様と共に拡げていく
            </p>
            <h3>バリュー</h3>
            <h4>1. ユーザーフレンドリー</h4>
            <h4>2. チームワーク「信頼」</h4>
            <h4>3. 品質重視 + 5S</h4>
          </div>
        </div>

        <div id="message" name="message" className="row">
          <div className="h-wrapper">
            <h2>代表の想い</h2>
          </div>
        </div>
        <div className="row">
          <div className="container">
            <div className="wrapper">
              <h4>未来の「駐車体験」を形にする</h4>
              <p className="pc-only">
                「より便利でより安全、よりストレスのない、ユーザーフレンドリーな駐車体験を至るところにお届けしたい」
                <br />
                <br />
                その一途な思いで、私たちは「ロック板のない駐車場」や「スマートフォンから精算できるアプリ」などを先駆けて世の中に出してまいりました。
                <br />
                <br />
                私たちは引き続き、より高い利便性と安全性を兼ね備えた先進的/未来的な駐車場機器や運営管理サービスを、
                <br />
                「ハードウエア」に「ソフトウエア/アルゴリズム/ネットワーク」を組み合わせることでご提供してまいります。
                <br />
                <br />
                未来の「駐車体験」を形にする
                <br />
                是非私たちアイテックにも、そのお手伝いをさせてください。
                <br />
                <br />
                株式会社アイテック 代表取締役　前川 卓志
              </p>
            </div>
            <div className="clear"></div>
            <p className="sp-only">
              「より便利でより安全、よりストレスのない、ユーザーフレンドリーな駐車体験を至るところにお届けしたい」
              <br />
              <br />
              その一途な思いで、私たちは「ロック板のない駐車場」や「スマートフォンから精算できるアプリ」などを先駆けて世の中に出してまいりました。
              <br />
              <br />
              私たちは引き続き、より高い利便性と安全性を兼ね備えた先進的/未来的な駐車場機器や運営管理サービスを、
              <br />
              「ハードウエア」に「ソフトウエア/アルゴリズム/ネットワーク」を組み合わせることでご提供してまいります。
              <br />
              <br />
              未来の「駐車体験」を形にする
              <br />
              是非私たちアイテックにも、そのお手伝いをさせてください。
              <br />
              <br />
              株式会社アイテック　代表取締役 前川 卓志
            </p>
          </div>
        </div>

        <div id="value" className="row">
          <div className="h-wrapper">
            <h2>私たちが提供したい価値</h2>
          </div>
        </div>

        <div className="row">
          <div className="container value">
            <h4>駐車場事業のA to Z、それ以上を、わたしたちはお届けします。</h4>

            <p>
              土地オーナー様、運営会社様、そして駐車場を利用になる方々のよりよいパートナーとなるため、アイテックはトータルクリエイターとして技術を、サービスを、磨きつづけます。国内最大手の駐車場機器専業メーカーとなったいま、その自負をもって、わたしたちは次なるチャレンジへ。
            </p>

            <div className="wrapper upper">
              <div className="box">
                <img
                  src="/assets/images/common/icon-about-plan.png"
                  alt="icon-about-plan"
                />
                <div className="sp-wrap">
                  <p className="title">企画</p>
                  <p className="desc">
                    土地オーナー様、運営会社様と真正面から向き合い、ニーズに合わせた最適な駐車場形態、システムを企画、提案いたします。
                  </p>
                </div>
              </div>
              <img
                className="arrow right"
                src="/assets/images/common/arrow-right-rgb.png"
                alt="arrow-right-rgb"
              />
              <img
                className="arrow down"
                src="/assets/images/common/arrow-down-rgb.png"
                alt="arrow-down-rgb"
              />

              <div className="box">
                <img
                  src="/assets/images/common/icon-about-dev.png"
                  alt="icon-about-dev"
                />
                <div className="sp-wrap">
                  <p className="title">開発</p>
                  <p className="desc">
                    つねにお客様の収益アップにつながる新たな機器・システムの開発をめざし、もてる技術とノウハウのすべてを傾けています。
                  </p>
                </div>
              </div>
              <img
                className="arrow right"
                src="/assets/images/common/arrow-right-rgb.png"
                alt="arrow-right-rgb"
              />
              <img
                className="arrow down"
                src="/assets/images/common/arrow-down-rgb.png"
                alt="arrow-down-rgb"
              />

              <div className="box">
                <img
                  src="/assets/images/common/icon-about-product.png"
                  alt="icon-about-product"
                />
                <div className="sp-wrap">
                  <p className="title">製造</p>
                  <p className="desc">
                    厳しい品質管理のもと、信頼性・安全性・耐久性・コストパフォーマンスにすぐれた機器を製造。工程の効率化や新素材の採用など、環境負荷の軽減にも取り組んでいます。
                  </p>
                </div>
              </div>
              <img
                className="arrow right"
                src="/assets/images/common/arrow-right-rgb.png"
                alt="arrow-right-rgb"
              />
              <img
                className="arrow down"
                src="/assets/images/common/arrow-down-rgb.png"
                alt="arrow-down-rgb"
              />
            </div>
            <div className="wrapper lower">
              <img
                className="arrow right"
                src="/assets/images/common/arrow-right-rgb.png"
                alt="arrow-right-rgb"
              />
              <div className="box">
                <img
                  src="/assets/images/common/icon-about-store.png"
                  alt="icon-about-store"
                />
                <div className="sp-wrap">
                  <p className="title">販売</p>
                  <p className="desc">
                    小スペース用から大規模スペース用まで、多彩な機器・システムのラインアップをご用意しています。
                  </p>
                </div>
              </div>
              <img
                className="arrow right"
                src="/assets/images/common/arrow-right-rgb.png"
                alt="arrow-right-rgb"
              />
              <img
                className="arrow down"
                src="/assets/images/common/arrow-down-rgb.png"
                alt="arrow-down-rgb"
              />

              <div className="box">
                <img
                  src="/assets/images/common/icon-about-construction.png"
                  alt="icon-about-construction"
                />
                <div className="sp-wrap">
                  <p className="title">施工</p>
                  <p className="desc">
                    駐車場の設計後、敷地の舗装から機器の設置、引き渡しまで、土地を遊ばせないよう、スピーディに施工いたします。また、施工前の近隣へのご挨拶など、細かな気配りを欠かしません。
                  </p>
                </div>
              </div>
              <img
                className="arrow right"
                src="/assets/images/common/arrow-right-rgb.png"
                alt="arrow-right-rgb"
              />
              <img
                className="arrow down"
                src="/assets/images/common/arrow-down-rgb.png"
                alt="arrow-down-rgb"
              />

              <div className="box">
                <img
                  src="/assets/images/common/icon-about-support.png"
                  alt="icon-about-support"
                />
                <div className="sp-wrap">
                  <p className="title">管理</p>
                  <p className="desc">
                    集金業務はもちろん、駐車場管理にかかわるすべてをおまかせいただけます。また、ネットワークシステムにより、入出庫・精算状況のデータの提供および分析、稼働率向上のためのご提案も行っています。
                  </p>
                </div>
              </div>
              <img
                className="arrow right"
                src="/assets/images/common/arrow-right-rgb.png"
                alt="arrow-right-rgb"
              />
              <img
                className="arrow down"
                src="/assets/images/common/arrow-down-rgb.png"
                alt="arrow-down-rgb"
              />

              <div className="box">
                <img
                  src="/assets/images/common/icon-about-maintainance.png"
                  alt="icon-about-maintainance"
                />
                <div className="sp-wrap">
                  <p className="title">メンテナンス</p>
                  <p className="desc">
                    機器に故障が発生した場合など、トラブルやクレームにはアイテックのコールセンターが24時間・365日対応いたします。もちろん、機器の保守点検や駐車場の定期的な清掃も怠りません。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Mvv
